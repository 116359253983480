import { cx } from "@uxf/core/utils/cx";
import React, { FC, PropsWithChildren, ReactNode } from "react";

interface Props {
    className?: string;
    contentClassName?: string;
    description?: ReactNode;
    isPage?: boolean;
    name?: string;
    position?: "row" | "column";
    textPosition?: "center" | "left";
    title?: ReactNode;
    variant?: "violet" | "violetLight" | "white";
    withoutContainer?: boolean;
}

export const SectionLayout: FC<PropsWithChildren<Props>> = ({
    children,
    className,
    contentClassName,
    description,
    isPage,
    name,
    position = "column",
    textPosition = "left",
    title,
    variant = "white",
    withoutContainer = false,
}) => {
    const nameClassName = cx(
        "ui-typo-overline-large mb-10 whitespace-nowrap opacity-40",
        textPosition === "center" && "text-center",
        position === "column" && "ui-container",
    );

    return (
        <section
            className={cx(
                "mb-20 md:mb-28 lg:mb-48",
                isPage && "pt-10 md:pt-20 xl:pt-32",
                variant === "violet" && "bg-uxfViolet text-white",
                variant === "violetLight" && "bg-uxfVioletLightest",
                className,
            )}
        >
            <div className={cx(position === "row" && "ui-container lg:flex lg:justify-between lg:space-x-32")}>
                {title ? <p className={nameClassName}>{name}</p> : <h2 className={nameClassName}>{name}</h2>}
                <div className={position === "row" ? cx(contentClassName) : ""}>
                    {title && (
                        <div className={cx("relative", position === "column" && "ui-container")}>
                            <div
                                className={cx(
                                    "mb-12 w-full sm:mb-20 lg:mb-28",
                                    textPosition === "left" && position === "column" && "lg:w-10/12",
                                )}
                            >
                                {isPage ? (
                                    <h1 className={cx("ui-typo-h1 w-full", textPosition === "center" && "text-center")}>
                                        {title}
                                    </h1>
                                ) : (
                                    <h2 className={cx("ui-typo-h2 w-full", textPosition === "center" && "text-center")}>
                                        {title}
                                    </h2>
                                )}
                                {typeof description === "string" ? (
                                    <p className="ui-typo-medium mt-10 opacity-75">{description}</p>
                                ) : (
                                    description
                                )}
                            </div>
                        </div>
                    )}
                    <div
                        className={cx(
                            position === "column" && contentClassName,
                            position === "column" && !withoutContainer && "ui-container",
                        )}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </section>
    );
};
